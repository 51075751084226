import { Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import EditPdfMedia from "../PdfMedia/EditPdfMedia";
import { useRef } from "react";
import PdfShowMore from "../PdfMedia/PdfShowMore";
import { Base64 } from "js-base64";
import { getInspectionData } from "../../../Redux/Actions/CommonActions";
import { useDispatch } from "react-redux";
import {
  getImageDimensions,
  resizeImage,
} from "../../../Helpers/HelperFunctions";
import { v4 as uuidv4 } from "uuid";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import axios from "axios";
import toast from "react-hot-toast";
import Compressor from "compressorjs";
import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import { origin } from "../../../Config/Api-constants";
import PreloaderWithText from "../../../Helpers/PreloaderWithText";
import ShowMoreLess from "../../LineDetails/ShowMoreLess";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import Preloader from "../../../Helpers/Preloader";
import { useLocation } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { filterObjectsUptoTemplate } from "../../../Helpers/HelperFunctions";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";

function MediaUploadElement({
  onDragtoggle,
  setOnDragToggle,
  selectedMediaFiles,
  setSelectedMediaFiles,
  selectedImages,
  setSelectedImages,
  element,
  action,
  templateId,
  width,
  setWidth,
  height,
  setHeight,
  elementType,
  pdfCategoriesType,
  orderForMedia,
}) {
  const [inspectionResponse, setInspectionResponse] = useState();
  const [mediaData, setMediaData] = useState([]);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loaderText, setLoaderText] = useState("");
  const [showPdfEditModal, setShowPdfEditModal] = useState(false);
  const [currentMedia, setCurrentMedia] = useState();
  const [caption, setCaption] = useState();
  const storeData = useSelector((state) => state.CommonReducer);
  const fileInput = useRef(null);
  const editMediaInput = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const categoryId = location?.state?.category_id;
  let responseCount = 0;
  let videoUploadCount = 0;
  let imageuploadCount = 0;
  let successCount = 0;
  const matches = useMediaQuery("(min-width:1400px)");
  let errorCount = 0;
  let sizeErrorCount = 0;

  useEffect(() => {
    setInspectionResponse(storeData);
  }, [storeData]);

  let templateIndex =
    storeData?.inspectionData?.data?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
  let prevData = JSON.parse(JSON.stringify(storeData?.inspectionData?.data));
  delete prevData?.report_media_library_files;   
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let templateGuid = pdfTemplate?.template_guid;
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter(
    (item) => item?.merge_id == mergeId
  );

  useEffect(() => {
    let value = ElementpdfData?.[0]?.value;
    setMediaData(
      ElementpdfData !== null &&
        ElementpdfData !== undefined &&
        value !== null &&
        value?.length !== 0 &&
        value !== undefined
        ? value
        : []
    );
  }, [inspectionResponse]);

  useEffect(() => {
    if (storeData?.loader) setLoader(true);
    else {
      // loader && toast.success(storeData?.inspectionData?.message)
      setLoader2(false);
      setLoader(false);
    }
  }, [storeData?.loader]);

  useEffect(() => {
    if (storeData.inspectionData?.data) {
      setLoader(false);
      setLoader2(false);
    }
  }, [storeData.inspectionData?.data]);

  useEffect(() => {
    setLoader(false);
    setLoader2(false);
  }, [storeData.updatedInspectionData]);

  useEffect(() => {
    if (storeData?.mediaUpdate) {
      let sessionAssociateCompanyKey = Base64.decode(
        sessionStorage.getItem("AC")
      );
      let guid = sessionStorage.getItem("guid");
      let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
      dispatch(
        getInspectionData({
          guid: guid,
          associate_company: sessionAssociateCompanyKey,
          user_id: sessionUserId,
        })
      );
    }
  }, [storeData?.mediaUpdate]);

  const callInitiateAPI = () => {
    setLoader2(false);
    setLoaderText("");
    let sessionAssociateCompanyKey = Base64.decode(
      sessionStorage.getItem("AC")
    );
    let guid = sessionStorage.getItem("guid");
    let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
    dispatch(
      getInspectionData({
        guid: guid,
        associate_company: sessionAssociateCompanyKey,
        user_id: sessionUserId,
      })
    );
    setLoader(false);
  };

  async function convertBase64ToFile(
    base64String,
    filename,
    file,
    formData,
    fileType,
    currentMediaLength,
    orderVal,
    e,
    videoCount,
    type
  ) {
    setLoaderText(`uploading video ${videoUploadCount + 1} of ${videoCount}`);
    const arr = base64String[0].split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let uploadfile;
    if (type == "drag") {
      uploadfile = e;
    } else {
      uploadfile = e.target.files;
    }
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let thumbVideoFile = new File([u8arr], filename, { type: mime });
    let thumbFile;
    const { imgWidth, imgHeight } = await getImageDimensions(thumbVideoFile);
    const maxWidth = 320;
    const maxHeight = 320;
    const quality = 1;
    let newWidth, newHeight;

    if (imgWidth > imgHeight) {
      // Landscape orientation
      newHeight = maxHeight;
      newWidth = (maxHeight / imgHeight) * imgWidth;
    } else {
      // Portrait orientation
      newWidth = maxWidth;
      newHeight = (maxWidth / imgWidth) * imgHeight;
    }
    let thumbnailImage = await resizeImage(
      thumbVideoFile,
      newWidth,
      newHeight,
      quality
    );
    thumbFile = new File([thumbnailImage], `${thumbnailImage.name}`, {
      type: "image/jpeg",
    });
    let imgObj = {
      id: uuidv4(),
      url: null,
      name: file.name,
      size: file.size,
      type: file.type,
      edited: false,
      newUrl: null,
      online: "online",
      filetype: fileType,
      localUrl: null,
      OriginUrl: null,
      editCount: 0,
      onlineUrl: null,
      orginName: null,
      thumbnail: null,
      is_deleted: 0,
      order: currentMediaLength + orderVal,
      updated_at: await GetUTCTime(),
      description: null,
      OriginlocalUrl: null,
      isOriginUploaded: true,
    };
    let img_obj_string = JSON.stringify(imgObj);
    let params = {};

    params = {
      type: element?.type,
      video: file,
      video_thumbnail: thumbFile,
      inspection_guid: sessionStorage.getItem("guid"),
      template_id: templateId,
      img_object: img_obj_string,
      template_guid: templateGuid,
      img_type: "element",
      element_label: element?.label != null ? element?.label : "",
      order: orderForMedia,
      merge_id: mergeId,
      is_pdf_template: 1,
      category_id: categoryId,
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    // handleMediaUpload(formData)

    // sync API call
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem("CK");
    let sessionSecretKey = sessionStorage.getItem("SK");
    try {
      const response = await axios.post(
        `${baseUrl}upload_media_for_inspection_template`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
            "api-key": `${Base64.decode(sessionAPIKey)}`,
            platform: "web",
          },
        }
      );
      const result = response.data;
      responseCount++;
      videoUploadCount++;
      successCount++;
    } catch (error) {
      responseCount++;
      videoUploadCount++;
      errorCount++;
      toast.error(error?.message);
    }
    if (responseCount === uploadfile?.length) {
      if (successCount > 0)
        toast.success(
          `${successCount} ${successCount === 1 ? "media item has" : "media items have"
          } been uploaded successfully.`,
          {
            duration: 4000,
          }
        );
      if (errorCount > 0)
        toast.error(
          `${errorCount - sizeErrorCount > 0
            ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1 ? "item was" : "items were"
            } not uploaded, the file format is not supported.`
            : ""
          } ${sizeErrorCount > 0
            ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"
            } not uploaded because the media file is greater than 1024MB.`
            : ""
          }`,
          {
            duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
          }
        );
      callInitiateAPI();
      fileInput.current.value = null;
      editMediaInput.current.value = null;
      responseCount = 0;
      videoUploadCount = 0;
      imageuploadCount = 0;
      errorCount = 0;
      sizeErrorCount = 0;
      successCount = 0;
    }

    // return new File([u8arr], filename, {type:mime});
  }

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const handleAddMedia = async (e, type) => {
    let imageCount = 0;
    let videoCount = 0;
    let currentMediaLength = mediaData?.length;
    let fileSizeStatus = [];
    let formatStatus = [];
    let uploadfile;
    if (type == "drag") {
      uploadfile = e;
    } else {
      uploadfile = e.target.files;
    }
    setImage(uploadfile);
    for (let i = 1; i <= uploadfile.length; i++) {
      let formData = new FormData();
      let file = uploadfile[i - 1];
      const fileSize = file.size;
      const sizeInMB = Math.round(fileSize / (1024 * 1024));
      let fileTypeCheck = null;
      if (
        file?.type === "image/jpeg" ||
        file?.type === "image/png" ||
        file?.type === "image/jpg"
      ) {
        fileTypeCheck = "image";
      } else if (
        file?.type === "image/tiff" ||
        file?.type === "image/bmp" ||
        file?.type === "image/gif" ||
        file?.type === "image/svg" ||
        file?.type === "image/ico" ||
        file?.type === "image/x-icon"
      ) {
        fileTypeCheck = "unsupported";
      } else {
        fileTypeCheck = "video";
      }
      if (fileTypeCheck === "image" || fileTypeCheck === "unsupported")
        imageCount++;
      if (fileTypeCheck === "video") videoCount++;
    }

    setLoader2(true);
    if (uploadfile?.length === 0) setLoader2(false);
    for (let i = 1; i <= uploadfile?.length; i++) {
      let formData = new FormData();
      let file = uploadfile[i - 1];
      let fileType = null;
      if (
        file?.type === "image/jpeg" ||
        file?.type === "image/png" ||
        file?.type === "image/jpg"
      ) {
        fileType = "image";
      } else if (
        file?.type === "image/tiff" ||
        file?.type === "image/bmp" ||
        file?.type === "image/gif" ||
        file?.type === "image/svg" ||
        file?.type === "image/ico" ||
        file?.type === "image/x-icon"
      ) {
        fileType = "unsupported";
      } else {
        fileType = "video";
      }
      const fileSize = file?.size;
      const sizeInMB = Math?.round(fileSize / (1024 * 1024));
      if (sizeInMB >= 1024) {
        // toast.error("File size too Big, please select a file less than 500 MB");
        errorCount++;
        responseCount++;
        sizeErrorCount++;
        if (responseCount === uploadfile.length) {
          if (successCount > 0)
            toast.success(
              `${successCount} ${successCount === 1 ? "media item has" : "media items have"
              } been uploaded successfully. `,
              {
                duration: 4000,
              }
            );
          if (errorCount > 0)
            toast.error(
              `${errorCount - sizeErrorCount > 0
                ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1
                  ? "item was"
                  : "items were"
                } not uploaded, the file format is not supported.`
                : ""
              } ${sizeErrorCount > 0
                ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"
                } not uploaded because the media file is greater than 1024MB.`
                : ""
              }`,
              {
                duration: 5000, // Set the timeout duration to 5000 milliseconds (3 seconds)
              }
            );
          callInitiateAPI();
          fileInput.current.value = null;
          editMediaInput.current.value = null;
          responseCount = 0;
          videoUploadCount = 0;
          imageuploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else if (
        !file.type.match(
          /(image\/(png|jpg|jpeg))|(video\/(mp4|mov|quicktime))/
        ) ||
        file?.name?.toLowerCase()?.endsWith(".jfif")
      ) {
        errorCount++;
        responseCount++;
        if (responseCount === uploadfile.length) {
          if (successCount > 0)
            toast.success(
              `${successCount} ${successCount === 1 ? "media item has" : "media items have"
              } been uploaded successfully. `,
              {
                duration: 4000,
              }
            );
          if (errorCount > 0)
            toast.error(
              `${errorCount - sizeErrorCount > 0
                ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1
                  ? "item was"
                  : "items were"
                } not uploaded, the file format is not supported.`
                : ""
              } ${sizeErrorCount > 0
                ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"
                } not uploaded because the media file is greater than 1024MB.`
                : ""
              }`,
              {
                duration: 5000, // Set the timeout duration to 5000 milliseconds (3 seconds)
              }
            );
          callInitiateAPI();
          fileInput.current.value = null;
          editMediaInput.current.value = null;
          responseCount = 0;
          videoUploadCount = 0;
          imageuploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else {
        if (fileType === "image") {
          setLoaderText(
            `compressing image ${imageuploadCount + 1} of ${imageCount}`
          );
          let compressedFile;
          let thumbImage;
          const { imgWidth, imgHeight } = await getImageDimensions(file);
          const maxWidth = 320;
          const maxHeight = 320;
          const quality = 0.8;
          let newWidth, newHeight;
          if (imgWidth > imgHeight) {
            // Landscape orientation
            newHeight = maxHeight;
            newWidth = (maxHeight / imgHeight) * imgWidth;
          } else {
            // Portrait orientation
            newWidth = maxWidth;
            newHeight = (maxWidth / imgWidth) * imgHeight;
          }
          let thumbnailImage = await resizeImage(
            file,
            newWidth,
            newHeight,
            quality
          );
          thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, {
            type: "image/jpeg",
          });
          if (file?.size > 512000) {
            // =======
            try {
              const compressedFilee = await compressImage(file);
              compressedFile = new File(
                [compressedFilee],
                `${compressedFilee.name}`,
                { type: "image/jpeg" }
              );
              setLoaderText(
                `uploading image ${imageuploadCount + 1} of ${imageCount}`
              );
              let imgObj = {
                id: uuidv4(),
                url: null,
                name: compressedFile.name,
                size: compressedFile.size,
                type: compressedFile.type,
                edited: false,
                newUrl: null,
                online: "online",
                filetype: fileType,
                localUrl: null,
                OriginUrl: null,
                editCount: 0,
                onlineUrl: null,
                orginName: null,
                thumbnail: null,
                is_deleted: 0,
                order: currentMediaLength + i,
                updated_at: await GetUTCTime(),
                description: null,
                OriginlocalUrl: null,
                isOriginUploaded: true,
              };
              let img_obj_string = JSON.stringify(imgObj);
              let params = {};
              params = {
                image: compressedFile,
                type: element?.type,
                video_thumbnail: thumbImage,
                inspection_guid: sessionStorage.getItem("guid"),
                template_id: templateId,
                img_object: img_obj_string,
                template_guid: templateGuid,
                img_type: "element",
                element_label: element?.label != null ? element?.label : "",
                order: orderForMedia,
                merge_id: mergeId,
                is_pdf_template: 1,
                category_id: categoryId,
              };

              Object.keys(params).forEach((key) =>
                formData.append(key, params[key])
              );

              // sync API call
              let baseUrl = await origin();
              let sessionAPIKey = sessionStorage.getItem("CK");
              let sessionSecretKey = sessionStorage.getItem("SK");
              try {
                const response = await axios.post(
                  `${baseUrl}upload_media_for_inspection_template`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${Base64.decode(
                        sessionSecretKey
                      )}`,
                      "api-key": `${Base64.decode(sessionAPIKey)}`,
                      platform: "web",
                    },
                  }
                );
                const result = response.data;
                responseCount++;
                imageuploadCount++;
                successCount++;
              } catch (error) {
                toast.error(error?.message);
                responseCount++;
                errorCount++;
                imageuploadCount++;
              }
              if (responseCount === uploadfile.length) {
                if (successCount > 0)
                  toast.success(
                    `${successCount} ${successCount === 1 ? "media item has" : "media items have"
                    } been uploaded successfully. `,
                    {
                      duration: 4000,
                    }
                  );
                if (errorCount > 0)
                  toast.error(
                    `${errorCount - sizeErrorCount > 0
                      ? `${errorCount - sizeErrorCount}  Media ${errorCount - sizeErrorCount === 1
                        ? "item was"
                        : "items were"
                      } not uploaded, the file format is not supported.`
                      : ""
                    } ${sizeErrorCount > 0
                      ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"
                      } not uploaded because the media file is greater than 1024MB.`
                      : ""
                    }`,
                    {
                      duration: 5000, // Set the timeout duration to 5000 milliseconds (3 seconds)
                    }
                  );
                callInitiateAPI();
                fileInput.current.value = null;
                editMediaInput.current.value = null;
                responseCount = 0;
                videoUploadCount = 0;
                imageuploadCount = 0;
                errorCount = 0;
                sizeErrorCount = 0;
                successCount = 0;
              }
              // Do something with the compressed file
            } catch (error) {
              console.error("Error compressing image:", error);
            }

            // ======
          } else {
            compressedFile = file;
            setLoaderText(
              `uploading image ${imageuploadCount + 1} of ${imageCount}`
            );
            let imgObj = {
              id: uuidv4(),
              url: null,
              name: compressedFile.name,
              size: compressedFile.size,
              type: compressedFile.type,
              edited: false,
              newUrl: null,
              online: "online",
              filetype: fileType,
              localUrl: null,
              OriginUrl: null,
              editCount: 0,
              onlineUrl: null,
              orginName: null,
              thumbnail: null,
              is_deleted: 0,
              order: currentMediaLength + i,
              updated_at: await GetUTCTime(),
              description: null,
              OriginlocalUrl: null,
              isOriginUploaded: true,
            };
            let img_obj_string = JSON.stringify(imgObj);
            let params = {};
            params = {
              type: element?.type,
              image: compressedFile,
              video_thumbnail: thumbImage,
              inspection_guid: sessionStorage.getItem("guid"),
              template_id: templateId,
              img_object: img_obj_string,
              template_guid: templateGuid,
              img_type: "element",
              element_label: element?.label != null ? element?.label : "",
              order: orderForMedia,
              merge_id: mergeId,
              is_pdf_template: 1,
              category_id: categoryId,
            };

            Object.keys(params).forEach((key) =>
              formData.append(key, params[key])
            );

            // sync API call
            let baseUrl = await origin();
            let sessionAPIKey = sessionStorage.getItem("CK");
            let sessionSecretKey = sessionStorage.getItem("SK");
            try {
              const response = await axios.post(
                `${baseUrl}upload_media_for_inspection_template`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
                    "api-key": `${Base64.decode(sessionAPIKey)}`,
                    platform: "web",
                  },
                }
              );
              const result = response.data;
              responseCount++;
              imageuploadCount++;
              successCount++;
            } catch (error) {
              responseCount++;
              errorCount++;
              imageuploadCount++;
              toast.error(error?.message);
            }
            if (responseCount === uploadfile.length) {
              if (successCount > 0)
                toast.success(
                  `${successCount} ${successCount === 1 ? "media item has" : "media items have"
                  } been uploaded successfully.`,
                  {
                    duration: 4000,
                  }
                );
              if (errorCount > 0)
                toast.error(
                  `${errorCount - sizeErrorCount > 0
                    ? `${errorCount - sizeErrorCount}  media ${errorCount - sizeErrorCount === 1
                      ? "item was"
                      : "items were"
                    } not uploaded, the file format is not supported.`
                    : ""
                  } ${sizeErrorCount > 0
                    ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? "item was" : "items were"
                    } not uploaded because the media file is greater than 1024MB.`
                    : ""
                  }`,
                  {
                    duration: 5000, // Set the timeout duration to 5000 milliseconds (3 seconds)
                  }
                );
              callInitiateAPI();
              fileInput.current.value = null;
              editMediaInput.current.value = null;
              responseCount = 0;
              videoUploadCount = 0;
              imageuploadCount = 0;
              errorCount = 0;
              sizeErrorCount = 0;
              successCount = 0;
            }
          }
          // sync api call

          // handleMediaUpload(formData)
        } else if (fileType === "video") {
          setLoader2(true);
          setLoaderText(
            `generating thumbnail for video ${videoUploadCount + 1
            } of ${videoCount}`
          );
          await generateVideoThumbnails(file, 10).then(async (thumbs) => {
            await convertBase64ToFile(
              thumbs,
              "name",
              file,
              formData,
              fileType,
              currentMediaLength,
              i,
              e,
              videoCount,
              type
            );
          });
        }
      }
    }
  };
  let apiResponseDataFromStore = JSON?.parse(
    JSON?.stringify(storeData?.inspectionData?.data)
  );
  let PdfTemplateResponseData =
    apiResponseDataFromStore?.inspectiontemplates[templateIndex];

  const HandleMediaFromRML = async () => {
    let DescObject = ElementpdfData?.[0]?.value;
    const itemIndex = pdfTemplate?.pdf_data?.findIndex(
      (item) => item?.merge_id === mergeId
    );
    selectedMediaFiles.forEach(async (item) => {
      item.is_deleted = 0;
      item.updated_at = await GetUTCTime();
      item.edited = false;
      item.editCount = 0;
      item.times_used += 1;
    })
    if (itemIndex !== -1) {
      pdfTemplate.pdf_data[itemIndex].value = [
        ...DescObject,
        ...selectedMediaFiles,
      ];
      pdfTemplate.pdf_data[itemIndex].updated_at = await GetUTCTime();
      PdfTemplateResponseData.pdf_data[itemIndex].value.push(...selectedMediaFiles);
      PdfTemplateResponseData.pdf_data[itemIndex].updated_at =
        await GetUTCTime();
    }
    let filteredPdfData = prevData.inspectiontemplates[
      templateIndex
    ].pdf_data.filter((el) => el.merge_id === mergeId);
    prevData.inspectiontemplates[templateIndex].pdf_data = filteredPdfData;
    if (itemIndex === -1) {
      let newPDFdata = {
        category: { id: categoryId, prompt: "media" },
        show_in_pdf: 1,
        category_id: categoryId,
        order: orderForMedia,
        merge_id: mergeId,
        type: "MediaUploader",
        element_label: "media",
        category_name: "media",
        updated_at: await GetUTCTime(),
        value: selectedMediaFiles,
      };

      filteredPdfData?.push(newPDFdata);
    }
    prevData.inspectiontemplates[templateIndex].pdf_categories = [];
    let filteredObject = filterObjectsUptoTemplate(prevData, templateIndex);
    UpdateTemplateDataSpecific(
      dispatch,
      apiResponseDataFromStore,
      filteredObject
    );
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleAddMedia(acceptedFiles, "drag");
    },
    [handleAddMedia]
  );

  const onDropFn = useCallback(
    (acceptedFiles, type) => {
      if (type === "outside") {
        if (selectedMediaFiles?.length > 0) {
          HandleMediaFromRML();
        }
      }
    },
    [handleAddMedia]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <Preloader showPreloader={loader} />

      {showPdfEditModal && (
        <EditPdfMedia
          showPdfEditModal={showPdfEditModal}
          setShowPdfEditModal={setShowPdfEditModal}
          element={element}
          handleAddMedia={handleAddMedia}
          mediaData={mediaData}
          templateId={templateId}
          setLoader={setLoader}
          editMediaInput={editMediaInput}
        />
      )}

      <PreloaderWithText showPreloader={loader2} loadText={loaderText} />
      <div style={{ margin: "0px 0px 0px 10px", width: "98.5%" }}>
        <Grid
          container
          spacing={1}
          className={`${elementType === "inner" || elementType === "layout"
              ? "pdf_container"
              : "pdf_inner_container"
            }`}
          style={{
            border: (isDragActive || onDragtoggle) && "2px dashed #4282e2", 
          }}
          {...getRootProps({
            onDrop: (event) => onDropFn(event.dataTransfer.files, "outside"),
          })}
          onClick={(e) => e?.stopPropagation()}
        >
          <Grid item xs={12} className="line_detail_des_title">
            <span
              className={
                pdfCategoriesType == 1
                  ? (elementType !== "inner" && elementType !== "layout") ||
                    mediaData == ""
                    ? "details_title"
                    : "details_title_after_selection"
                  : "details_title"
              }
            >
              {pdfCategoriesType == 1 ? (
                (elementType !== "inner" && elementType !== "layout") ||
                  mediaData == "" ? (
                  ""
                ) : (
                  <DoneIcon sx={{ marginLeft: "-28px", position: "absolute" }} />
                )
              ) : (
                ""
              )}
              {element?.label}
            </span>
            <span
              className={`${action === "pdf" ? "details_action" : "inner_details_action"
                }`}
              onClick={() => setShowPdfEditModal(true)}
            >
              <EditIcon />
              Manage
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={storeData?.reportSideBarActive ? 12 : 4}
            lg={storeData?.reportSideBarActive ? (!matches ? 6 : 4) : 3}
          >
            <Typography
              className="add-media-content_for_line_details"
              onClick={() => fileInput.current.click()}
            >
              <input
                ref={fileInput}
                type="file"
                accept="image/*,video/*"
                onChange={handleAddMedia}
                style={{ display: "none" }}
                multiple
              />
              <div style={{ marginTop: "20px" }}>
                <AddCircleIcon />
              </div>
              <div style={{ textDecoration: "underline", color: "#4282e2" }}>
                Click to add media
              </div>
              <div>or drag and drop</div>
            </Typography>
          </Grid>
          {mediaData?.length !== 0 && mediaData !== "" ? (
            <PdfShowMore
              width={width}
              setWidth={setWidth}
              height={setHeight}
              setHeight={setHeight}
              body={mediaData}
              matches={matches}
              mediaData={mediaData}
              element={element}
              templateId={templateId}
              categoryId={categoryId}
              callInitiateAPI={callInitiateAPI}
            />
          ) : (
            <div></div>
          )}
        </Grid>
      </div> 
    </>
  );
}

export default MediaUploadElement;
